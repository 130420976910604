import React from "react";
import "./HomeAr.css";
import HeaderWeb from "../../Assets/Com/Header/Header";
import IosGif from "../../Assets/Svg/IOS.gif";
import AndroidGif from "../../Assets/Svg/Android.gif";
import HomeS2Line from "../../Assets/Svg/HomeS2_Line.svg";
import HomeS2Car from "../../Assets/Svg/Home_S2_Car.gif";
import HomeS2Bocket from "../../Assets/Svg/Home_S2_Bocket.gif";
import HomeS2Loction from "../../Assets/Svg/Home_S2_Location.gif";
import HomeS2ImgLoop from "../../Assets/Com/HomeS3/HomeS3Img";
import HomeS3P3Img from "../../Assets/Svg/HomeS3_P3_Img.svg";
import HomeS4P2Step1 from "../../Assets/Svg/HomeS4_P2_Step1.svg";
import HomeS4P2Step2 from "../../Assets/Svg/HomeS4_P2_Step2.svg";
import HomeS4P2Step3 from "../../Assets/Svg/HomeS4_P2_Step3.svg";
import HomeS4P2Step4 from "../../Assets/Svg/HomeS4_P2_Step4.svg";
import HomeS4P2Step5 from "../../Assets/Svg/HomeS4_P2_Step5.svg";
import HomeS2LineMob from "../../Assets/Svg/HomeS2_Line_Mob.svg";
export default function HomeArContent() {
  return (
    <>
      <HomeS1 />
      <HomeS2 />
      <HomeS3 />
      <HomeS4 />
    </>
  );
}
function HomeS1() {
  return (
    <>
      <div className="Home_Container_Ar">
        <div className="Home_S1_Ar">
          <div className="Home_S1_P1_Ar">
            <div className="Home_S1_P1_Animation_Ar">
              <div className="AniMation_L1_Ar">
                <h1> وعالية الجودة </h1>
                <br />
                <h1>رحلات آمنة </h1>
              </div>
              <div>
                <h1>بين يديك</h1>
              </div>
            </div>
            <div>
              <h2>اضغط وانطلق، نحن نساعدك على الوصول بأمان وسهولة. مستعد؟ </h2>
            </div>
            <div className="Home_S1_P1_BC_Ar">
              <a href="https://apps.apple.com/eg/app/mwsla/id1605197505" target="blank">
                <button>
                  <img src={IosGif} alt="" className="GifIos_Ar" /> تحميل
                </button>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.mwsla&pcampaignid=web_share" target="blank">
                <button>
                  <img src={AndroidGif} alt="" className="GifAndroid_Ar" /> تحميل
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function HomeS2() {
  return (
    <>
      <div className="HomeS2_Container_Ar">
        <div className="HomeS2_P1_Ar">
          <h1>سافر بأمان بين المدن اكتشف المزيد عن سلامتك</h1>
          <h2>من المكتب إلى المنزل، استرخِ ودعنا نُسهّل رحلتك</h2>
        </div>
        {/*  */}
        <div className="HomeS2_P2_Web_Ar">
          <div className="HomeS2_P2_L1_Ar">
            <div className="S2_P2_L1_L_Ar Lefted_Ar">
              <h1>أسعار موضحة مسبقاً</h1>
              <p>ستعرف دائماً تكلفة الرحلة قبل الطلب، بدون مفاجآت</p>
            </div>
            <img src={HomeS2Car} alt="" className="HomeS2CarC_Ar" />
            <div className="S2_P2_L1_R_Ar">
              <h1>رحلات آمنة </h1>
              <p>جميع الركاب يتم التعرف عليهم، وكثير منهم من كبرى الشركات</p>
            </div>
          </div>
          {/*  */}
          <div className="HomeS2LineWeb_Ar">
            <img src={HomeS2Line} alt="" />
          </div>
          {/*  */}
          <div className="HomeS2_P2_L1_Ar">
            <div className="S2_P2_L1_R_Ar">
              <img src={HomeS2Bocket} alt="" className="HomeS2CarL_Ar" />
            </div>
            <div className="S2_P2_L1_R_Ar Centred_Ar">
              <h1>تتبع الرحلة</h1>
              <p>يتم تتبع كل رحلة ويمكنك مشاركة مسارك في الوقت الفعلي</p>
            </div>
            <div className="S2_P2_L1_R_Ar">
              <img src={HomeS2Loction} alt="" className="HomeS2CarR_Ar" />
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        {/* Mob S2_P2 */}
        <div className="HomeS2_P2_Mob_Ar">
          <div className="RightOne_Ar">
            <div className="HomeS2_P2_L1_Mob_Ar">
              <img src={HomeS2Loction} alt="" className="HomeS2_P2_L1_MobImg_Ar" />
              <div className="HomeS2_P2_L1_Text_Mob_Ar">
                <h1>تتبع الرحلة</h1>
                <p>يتم تتبع كل رحلة ويمكنك مشاركة مسارك في الوقت الفعلي</p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="CenterOne_Ar">
            <div className="HomeS2_P2_L1_Mob_Ar">
              <img src={HomeS2Bocket} alt="" />
              <div className="HomeS2_P2_L1_Text_Mob_Ar">
                <h1>أسعار موضحة مسبقاً</h1>
                <p>ستعرف دائماً تكلفة الرحلة قبل الطلب، بدون مفاجآت</p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="RightOne_Ar">
            <div className="HomeS2_P2_L1_Mob_Ar">
              <img src={HomeS2Car} alt="" />
              <div className="HomeS2_P2_L1_Text_Mob_Ar">
                <h1>رحلات آمنة</h1>
                <p>جميع الركاب يتم التعرف عليهم، وكثير منهم من كبرى الشركات </p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="HomeS2LineMob_Ar">
            <img src={HomeS2LineMob} alt="" className="" />
          </div>
        </div>
        {/* End */}
      </div>
    </>
  );
}
function HomeS3() {
  return (
    <>
      <div className="HomeS3_Container_Ar">
        <div className="HomeS3_P1_Ar">
          <h1>خدماتنا</h1>
          <h2>خدماتنا متاحة من الأحد إلى الخميس</h2>
        </div>
        <div className="HomeS3_P2_Ar">
          <div className="HomeS3_P2_L_Ar">
            <h1>
              اطلب <span>سيارة</span>
            </h1>
            <p>اختر من بين مجموعة متنوعة من السيارات لتناسب احتياجاتك، سواء كنت بمفردك أو مع مجموعة</p>
          </div>
          <div className="HomeS3_P2_Img_Ar">
            <div className="Home_P8_S2_Shadow_Right_Ar"></div>
            <HomeS2ImgLoop />
            <div className="Home_P8_S2_Shadow_left_Ar"></div>
          </div>
        </div>
        <div className="HomeS3_P3_Ar">
          <div className="HomeS3_P2_L_Ar">
            <h1>
              اطلب <span>رحلة</span>
            </h1>
            <p>مسافات طويلة؟ لا تقلق، احجز رحلتك بين المدن مسبقًا</p>
          </div>
          <div>
            <img src={HomeS3P3Img} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
function HomeS4() {
  return (
    <>
      <div className="HomeS4_Container_Ar">
        <div className="HomeS4_P1_Ar">
          <h1>كيفية استخدام تطبيق مواصلة</h1>
        </div>
        <div className="HomeS4_P2_Ar">
          {/* step 1 */}
          <div className="HomeS4_P1_Step_Ar">
            <img src={HomeS4P2Step1} alt="" />
            <h1>افتح تطبيق مواصلة</h1>
          </div>
          {/* step 2 */}
          <div className="HomeS4_P1_Step_Ar">
            <img src={HomeS4P2Step2} alt="" />
            <h1>
              أرسل <span>الطلب</span> قبل موعد الرحلة بـ 24 ساعة
            </h1>
          </div>
          {/* step 3 */}
          <div className="HomeS4_P1_Step_Ar">
            <img src={HomeS4P2Step3} alt="" />
            <h1>
              اختر <span>رحلة</span> أو <span>سيارة</span>
            </h1>
          </div>
          {/* step 4 */}
          <div className="HomeS4_P1_Step_Ar">
            <img src={HomeS4P2Step4} alt="" className="HomeS4P2Step4_Ar" />
            <h1>
              اختر <span>موقعك</span>
            </h1>
          </div>
          {/* step 5 */}
          <div className="HomeS4_P1_Step_Ar">
            <img src={HomeS4P2Step5} alt="" className="HomeS4P2Step5_Ar" />
            <h1>
              يقوم <span>السائق</span> باصطحاب الراكب
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}
