import React from "react";
import HomeContent from "./Home";

export default function Home() {
  return (
    <>
      <HomeContent />
    </>
  );
}
