import React from "react";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { LanguageProvider } from "./LanguageContext";
import LanguageRoutes from "./LanguageRoutes";

export default function App() {
  return (
    <LanguageProvider>
      <Router>
        <LanguageRoutes />
      </Router>
    </LanguageProvider>
  );
}
