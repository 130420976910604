import React from "react";
import "./Footer.css";
import IosGif from "../../Svg/IOS.gif";
import AndroidGif from "../../Svg/Android.gif";
import LineSpace from "../../Svg/Line_Space.svg";
import InstaIcon from "../../Svg/Insta_Icon.svg";
import FaceIcon from "../../Svg/FaceBook_Icon.svg";
export default function Footer() {
  return (
    <>
      <div className="Footer">
        <div className="Footer_P1">
          <h1>
            MWSLA is a mobile application for Bus Booking and you can reach the team at <a href="mailto:info@mwsla.co">info@mwsla.co</a>
          </h1>

          <p>Privacy Policy</p>
        </div>
        <div className="Footer_P2">
          <a href="https://apps.apple.com/eg/app/mwsla/id1605197505" target="blank">
            <img src={IosGif} alt="" className="IosFooter" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.mwsla&pcampaignid=web_share" target="blank">
            <img src={AndroidGif} alt="" className="AndroidFooter" />
          </a>
        </div>
        <div className="Footer_P3">
          <img src={LineSpace} alt="" className="Line_Space" />
        </div>
        <div className="Footer_P4">
          <div className="Footer_Soical">
            <a href="https://www.instagram.com/mwsla.eg?igsh=Y2hhY3BwMDZoMXpo" target="blank">
              <img src={InstaIcon} alt="" />
            </a>
            <a href="https://www.facebook.com/mwsla.eg" target="blank">
              <img src={FaceIcon} alt="" />
            </a>
          </div>
          <div className="Footer_Copy">
            <h1>Copyright © MWSLA</h1>
          </div>
        </div>
      </div>
    </>
  );
}
