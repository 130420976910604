import React, { useEffect, useState } from "react";
import "./AboutAr.css";
import AboutS1P1Img from "../../Assets/Svg/About_S1_P1.svg";
import IosGif from "../../Assets/Svg/IOS.gif";
import AndroidGif from "../../Assets/Svg/Android.gif";
import About_S2_P1 from "../../Assets/Svg/About_S2_P1.svg";
import About_S2_P2 from "../../Assets/Svg/About_S2_P2.svg";
import About_S2_P3 from "../../Assets/Svg/About_S2_P3.svg";
export default function AboutContent() {
  return (
    <>
      <div className="About_Container_Ar">
        <div className="About_S1_Ar">
          <div className="About_S1_P1_Ar">
            <img src={AboutS1P1Img} alt="" />
            <h1>مواصلة هو تطبيق للنقل الجماعي تم تطويره خصيصًا لتلبية احتياجات شريحة واسعة من الناس للتنقل بين محافظات مصر في الرحلات اليومية ، مع تقديم خدمة مميزة ومريحة بأسعار معقولة</h1>
            <h1>يركز فريق مواصلة على توفير تجربة مستخدم متميزة من خلال تقديم خدمة عالية الجودة وراحة تامة للعملاء</h1>
          </div>
        </div>
        <div className="About_S2_Ar">
          <div className="About_S2_P1 About_One_Ar">
            <div className="About_S2_P1_R_Ar">
              <img src={About_S2_P1} alt="" className="About_S2_P1_img_Ar" />
            </div>
            <div className="About_S2_P1_L_Ar">
              <h1>رحلتك بين يديك</h1>
              <p>تطبيق واحد، خيارات لا حصر لها تلائم احتياجاتك</p>
              <div className="About_S2_P1_BC_Ar">
                <a href="https://apps.apple.com/eg/app/mwsla/id1605197505" target="blank">
                  <button>
                    <img src={IosGif} alt="" className="GifIos" /> تحميل
                  </button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mwsla&pcampaignid=web_share" target="blank">
                  <button>
                    <img src={AndroidGif} alt="" className="GifAndroid" /> تحميل
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* P2 */}
          <div className="About_S2_P1 About_Two_Ar">
            <div className="About_S2_P1_L_Ar">
              <h1>معاك على الطريق</h1>
              <p>من خلال ميزة السلامة الخاصة المدمجة في تطبيقنا، وفريق دعم على مدار الساعة لمساعدتك في حالات الطوارئ، أنت بين أيدٍ أمينة</p>
            </div>
            <div className="About_S2_P1_R_Ar">
              <img src={About_S2_P2} alt="" className="About_S2_P1_img_Ar" />
            </div>
          </div>
          {/* P3 */}
          <div className="About_S2_P1 About_Three_Ar">
            <div className="About_S2_P1_R">
              <img src={About_S2_P3} alt="" className="About_S2_P1_img_Ar" />
            </div>
            <div className="About_S2_P1_L_Ar">
              <h1>معايير جودة عالية</h1>
              <p>ليس سائقونا ودودين فقط، بل هم أيضًا محترفون ممتازون يقودون سيارات حديثة اجتازت فلاتر الجودة لدينا، وهم يسعون دائماً لإرضائك </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
