import React from "react";
import "./Home.css";
import HeaderWeb from "../../Assets/Com/Header/Header";
import IosGif from "../../Assets/Svg/IOS.gif";
import AndroidGif from "../../Assets/Svg/Android.gif";
import HomeS2Line from "../../Assets/Svg/HomeS2_Line.svg";
import HomeS2Car from "../../Assets/Svg/Home_S2_Car.gif";
import HomeS2Bocket from "../../Assets/Svg/Home_S2_Bocket.gif";
import HomeS2Loction from "../../Assets/Svg/Home_S2_Location.gif";
import HomeS2ImgLoop from "../../Assets/Com/HomeS3/HomeS3Img";
import HomeS3P3Img from "../../Assets/Svg/HomeS3_P3_Img.svg";
import HomeS4P2Step1 from "../../Assets/Svg/HomeS4_P2_Step1.svg";
import HomeS4P2Step2 from "../../Assets/Svg/HomeS4_P2_Step2.svg";
import HomeS4P2Step3 from "../../Assets/Svg/HomeS4_P2_Step3.svg";
import HomeS4P2Step4 from "../../Assets/Svg/HomeS4_P2_Step4.svg";
import HomeS4P2Step5 from "../../Assets/Svg/HomeS4_P2_Step5.svg";
import HomeS2LineMob from "../../Assets/Svg/HomeS2_Line_Mob.svg";
export default function HomeContent() {
  return (
    <>
      <HomeS1 />
      <HomeS2 />
      <HomeS3 />
      <HomeS4 />
    </>
  );
}
function HomeS1() {
  return (
    <>
      <div className="Home_Container">
        <div className="Home_S1">
          <div className="Home_S1_P1">
            <div className="Home_S1_P1_Animation">
              <div className="AniMation_L1">
                <h1>Safe, </h1>
                <h1> Quality rides </h1>
              </div>
              <div>
                <h1>your Fingertips</h1>
              </div>
            </div>
            <div>
              <h2>Click and go, we’ll help you get there safely and easily. Ready?</h2>
            </div>
            <div className="Home_S1_P1_BC">
              <a href="https://apps.apple.com/eg/app/mwsla/id1605197505" target="blank">
                <button>
                  <img src={IosGif} alt="" className="GifIos" /> Download
                </button>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.mwsla&pcampaignid=web_share" target="blank">
                <button>
                  <img src={AndroidGif} alt="" className="GifAndroid" /> Download
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function HomeS2() {
  return (
    <>
      <div className="HomeS2_Container">
        <div className="HomeS2_P1">
          <h1>Enjoy a safer way between the cities and Learn More about safety</h1>
          <h2>To the office, back home. Whatever the journey, relax and let us make your journey easier.</h2>
        </div>
        {/*  */}
        <div className="HomeS2_P2_Web">
          <div className="HomeS2_P2_L1">
            <div className="S2_P2_L1_L Lefted">
              <h1>Upfront Prices</h1>
              <p>You'll always know the journey cost before you order, no shocks or surprises.</p>
            </div>
            <img src={HomeS2Car} alt="" className="HomeS2CarC" />
            <div className="S2_P2_L1_R">
              <h1>Safe Journeys </h1>
              <p>All passengers are identified and a large number of them are from major companies.</p>
            </div>
          </div>
          {/*  */}
          <div className="HomeS2LineWeb">
            <img src={HomeS2Line} alt="" />
          </div>
          {/*  */}
          <div className="HomeS2_P2_L1">
            <div className="S2_P2_L1_R">
              <img src={HomeS2Bocket} alt="" className="HomeS2CarL" />
            </div>
            <div className="S2_P2_L1_R Centred">
              <h1>Journey Tracking</h1>
              <p>Each journey is tracked and you can share your route in real-time</p>
            </div>
            <div className="S2_P2_L1_R">
              <img src={HomeS2Loction} alt="" className="HomeS2CarR" />
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        {/* Mob S2_P2 */}
        <div className="HomeS2_P2_Mob">
          <div className="RightOne">
            <div className="HomeS2_P2_L1_Mob">
              <img src={HomeS2Loction} alt="" className="HomeS2_P2_L1_MobImg" />
              <div className="HomeS2_P2_L1_Text_Mob">
                <h1>Journey Tracking</h1>
                <p>Each journey is tracked and you can share your route in real-time</p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="CenterOne">
            <div className="HomeS2_P2_L1_Mob">
              <img src={HomeS2Bocket} alt="" />
              <div className="HomeS2_P2_L1_Text_Mob">
                <h1>Upfront Prices</h1>
                <p>You'll always know the journey cost before you order, no shocks or surprises.</p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="RightOne">
            <div className="HomeS2_P2_L1_Mob">
              <img src={HomeS2Car} alt="" />
              <div className="HomeS2_P2_L1_Text_Mob">
                <h1>Safe Journeys</h1>
                <p>All passengers are identified and a large number of them are from major companies.</p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="HomeS2LineMob">
            <img src={HomeS2LineMob} alt="" className="" />
          </div>
        </div>
        {/* End */}
      </div>
    </>
  );
}
function HomeS3() {
  return (
    <>
      <div className="HomeS3_Container">
        <div className="HomeS3_P1">
          <h1>Our Services</h1>
          <h2>our services are available from Sunday to Thursday</h2>
        </div>
        <div className="HomeS3_P2">
          <div className="HomeS3_P2_L">
            <h1>
              Request a <span>Car</span>
            </h1>
            <p>Choose the perfect car for your needs: from solo trips to group adventures</p>
          </div>
          <div className="HomeS3_P2_Img">
            <div className="Home_P8_S2_Shadow_Right"></div>
            <HomeS2ImgLoop />
            <div className="Home_P8_S2_Shadow_left"></div>
          </div>
        </div>
        <div className="HomeS3_P3">
          <div>
            <img src={HomeS3P3Img} alt="" />
          </div>
          <div className="HomeS3_P2_L">
            <h1>
              Request a <span>Line</span>
            </h1>
            <p>Long-distance? We've got you covered. Book your line from city to city ahead</p>
          </div>
        </div>
      </div>
    </>
  );
}
function HomeS4() {
  return (
    <>
      <div className="HomeS4_Container">
        <div className="HomeS4_P1">
          <h1>How to use the Mwsla App</h1>
        </div>
        <div className="HomeS4_P2">
          {/* step 1 */}
          <div className="HomeS4_P1_Step">
            <h1>
              1- Open the <span>MWSLA</span> App
            </h1>
            <img src={HomeS4P2Step1} alt="" />
          </div>
          {/* step 2 */}
          <div className="HomeS4_P1_Step">
            <h1>
              2- Send the <span>Request</span> 24 hours before the trip time
            </h1>
            <img src={HomeS4P2Step2} alt="" />
          </div>
          {/* step 3 */}
          <div className="HomeS4_P1_Step">
            <h1>
              3- Choose a <span>Line</span> or a <span>Car</span>
            </h1>
            <img src={HomeS4P2Step3} alt="" />
          </div>
          {/* step 4 */}
          <div className="HomeS4_P1_Step">
            <h1>
              4- Choose your <span>Location</span>{" "}
            </h1>
            <img src={HomeS4P2Step4} alt="" className="HomeS4P2Step4" />
          </div>
          {/* step 5 */}
          <div className="HomeS4_P1_Step">
            <h1>
              5- The driver picks up the <span>Passenger</span>
            </h1>
            <img src={HomeS4P2Step5} alt="" className="HomeS4P2Step5" />
          </div>
        </div>
      </div>
    </>
  );
}
