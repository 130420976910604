import React, { useEffect, useState } from "react";
import "./About.css";
import AboutS1P1Img from "../../Assets/Svg/About_S1_P1.svg";
import IosGif from "../../Assets/Svg/IOS.gif";
import AndroidGif from "../../Assets/Svg/Android.gif";
import About_S2_P1 from "../../Assets/Svg/About_S2_P1.svg";
import About_S2_P2 from "../../Assets/Svg/About_S2_P2.svg";
import About_S2_P3 from "../../Assets/Svg/About_S2_P3.svg";
export default function AboutContent() {
  return (
    <>
      <div className="About_Container">
        <div className="About_S1">
          <div className="About_S1_P1">
            <img src={AboutS1P1Img} alt="" />
            <h1>"Mwsla" is a smart mass transportation App Launched specially developed to meet the needs of the wide scope of people for moving across the governates Egypt on regular daily trips, with offering premium and convenient service at affordable rates</h1>
            <h1>"Mwsla" team core value is providing the users with best service quality, comfort and best customer experience</h1>
          </div>
        </div>
        <div className="About_S2">
          <div className="About_S2_P1 About_One">
            <div className="About_S2_P1_L">
              <h1>Your Ride, A Tap Away</h1>
              <p>Ride your way One app, limitless options, we adapt to you.</p>
              <div className="About_S2_P1_BC">
                <a href="https://apps.apple.com/eg/app/mwsla/id1605197505" target="blank">
                  <button>
                    <img src={IosGif} alt="" className="GifIos" /> Download
                  </button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mwsla&pcampaignid=web_share" target="blank">
                  <button>
                    <img src={AndroidGif} alt="" className="GifAndroid" /> Download
                  </button>
                </a>
              </div>
            </div>
            <div className="About_S2_P1_R">
              <img src={About_S2_P1} alt="" className="About_S2_P1_img" />
            </div>
          </div>
          {/* P2 */}
          <div className="About_S2_P1 About_Two">
            <div className="About_S2_P1_R">
              <img src={About_S2_P2} alt="" className="About_S2_P1_img" />
            </div>
            <div className="About_S2_P1_L">
              <h1>With you on the Road</h1>
              <p>With special safety features built-in to our app, and a team ready 24/7 to help in the case of an emergency, you’re in safe hands.</p>
            </div>
          </div>
          {/* P3 */}
          <div className="About_S2_P1 About_Three">
            <div className="About_S2_P1_L">
              <h1>High quality standards</h1>
              <p>Not only are our drivers a friendly bunch, they’re also excellent professionals in modern vehicles that have passed our quality filters, and they’re committed to giving you a great service.</p>
            </div>
            <div className="About_S2_P1_R">
              <img src={About_S2_P3} alt="" className="About_S2_P1_img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
