import React, { useState, useEffect, useRef } from "react";
import HomeS3Img1 from "../../Svg/HomeS3Img1.svg";
import HomeS3Img2 from "../../Svg/HomeS3Img2.svg";
import HomeS3Img3 from "../../Svg/HomeS3Img3.svg";
import HomeS3Img4 from "../../Svg/HomeS3Img4.svg";

import "./HomeS3Img.css";
export default function HomeS2ImgLoop() {
  const [offset, setOffset] = useState(0);
  const images = [HomeS3Img1, HomeS3Img2, HomeS3Img3, HomeS3Img4];
  const imageRef = useRef(null);

  const updatePosition = () => {
    setOffset((prevOffset) => {
      const elementWidth = imageRef.current.scrollWidth;
      const resetOffset = elementWidth / 2;
      return prevOffset >= resetOffset ? 0 : prevOffset + 1;
    });
  };

  useEffect(() => {
    const interval = setInterval(updatePosition, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="HomeS3_P2_Img">
      <div
        ref={imageRef}
        style={{ transform: `translateX(-${offset}px)`, display: "flex" }}
      >
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`HomeS2Img${index + 1}`}
            className="HomeS2Img"
          />
        ))}
        {/* we repeat it to not make a space */}
        {images.map((img, index) => (
          <img
            key={index + images.length}
            src={img}
            alt={`HomeS2Img${index + 1 + images.length}`}
            className="HomeS2Img"
          />
        ))}
      </div>
    </div>
  );
}
