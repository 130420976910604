import React from "react";
import AboutContent from "./About";

export default function About() {
  return (
    <>
      <AboutContent />
    </>
  );
}
