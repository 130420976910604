import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import HeaderLogo from "../../Svg/Header_Logo.svg";
import LanguageIcon from "../../Svg/Language_Icon.svg";
import "./HeaderAbout.css";

export default function HeaderArAbout() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageSwitch = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.includes("/ar"); // Check if current language is Arabic

    if (isArabic) {
      const basePath = currentPath.slice(0, -3); // Remove "/ar" from the current path

      // If the basePath is empty, it means we are at the root in Arabic version
      const newPath = basePath === "" ? "/" : basePath;

      navigate(newPath, { replace: true }); // Navigate without pushing a new history entry
    }
  };
  return (
    <div className="Header_About_Container">
      <div className="Header_Menu">
        <div className="Language_Dropdown">
          <button onClick={() => setShowDropdown(!showDropdown)}>
            <img src={LanguageIcon} alt="Language" />
          </button>
          {showDropdown && (
            <div className="Dropdown_Menu">
              <button onClick={() => handleLanguageSwitch("en")}>English</button>
            </div>
          )}
        </div>
        <Link to="/about/ar" className="Header_Text_Ar">
          عن الشركة
        </Link>
        <Link to="/ar" className="Header_Text_Ar">
          الرئيسية
        </Link>
      </div>
      <div className="Header_Logo">
        <Link to="/ar">
          <img src={HeaderLogo} alt="Logo" />
        </Link>
      </div>
    </div>
  );
}
